/*
    C-DIM EXTENSION
    Extension du composant générique c-dim
    https://github.com/ita-design-system/c-dim.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-dim selon les besoins du projet
*/
$briks-components-generic: (
    // Nom du composant, obligatoirement dim
    dim: ( 
        // Extension activée true ou false
        enabled: true, 
        // Responsive activée true ou false pour l'extension
        responsive: true, 
        // Valeurs par défaut de l'extension
        defaults: (),
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: (
            p-11: ( 
                padding: my-spacing(11)
            ),
            p-10: (
                padding: my-spacing(10)
            ),
            p-9: (
                padding: my-spacing(9)
            ),
            p-8: (
                padding: my-spacing(8)
            ),
            p-7: (
                padding: my-spacing(7)
            ),
            p-6: (
                padding: my-spacing(6)
            ),
            p-5: (
                padding: my-spacing(5)
            ),
            p-4: (
                padding: my-spacing(4)
            ),
            p-3: (
                padding: my-spacing(3)
            ),
            p-2: (
                padding: my-spacing(2)
            ),
            p-1: (
                padding: my-spacing(1)
            ),
            p-0: (
                padding: 0
            ),
            pt-11: (
                padding-top: my-spacing(11)
            ),
            pt-10: (
                padding-top: my-spacing(10)
            ),
            pt-9: (
                padding-top: my-spacing(9)
            ),
            pt-8: (
                padding-top: my-spacing(8)
            ),
            pt-7: (
                padding-top: my-spacing(7)
            ),
            pt-6: (
                padding-top: my-spacing(6)
            ),
            pt-5: (
                padding-top: my-spacing(5)
            ),
            pt-4: (
                padding-top: my-spacing(4)
            ),
            pt-3: (
                padding-top: my-spacing(3)
            ),
            pt-2: (
                padding-top: my-spacing(2)
            ),
            pt-1: (
                padding-top: my-spacing(1)
            ),
            pt-0: (
                padding-top: 0
            ),
            pr-11: (
                padding-right: my-spacing(11)
            ),
            pr-10: (
                padding-right: my-spacing(10)
            ),
            pr-9: (
                padding-right: my-spacing(9)
            ),
            pr-8: (
                padding-right: my-spacing(8)
            ),
            pr-7: (
                padding-right: my-spacing(7)
            ),
            pr-6: (
                padding-right: my-spacing(6)
            ),
            pr-5: (
                padding-right: my-spacing(5)
            ),
            pr-4: (
                padding-right: my-spacing(4)
            ),
            pr-3: (
                padding-right: my-spacing(3)
            ),
            pr-2: (
                padding-right: my-spacing(2)
            ),
            pr-1: (
                padding-right: my-spacing(1)
            ),
            pr-0: (
                padding-right: 0
            ),
            pb-11: (
                padding-bottom: my-spacing(11)
            ),
            pb-10: (
                padding-bottom: my-spacing(10)
            ),
            pb-9: (
                padding-bottom: my-spacing(9)
            ),
            pb-8: (
                padding-bottom: my-spacing(8)
            ),
            pb-7: (
                padding-bottom: my-spacing(7)
            ),
            pb-6: (
                padding-bottom: my-spacing(6)
            ),
            pb-5: (
                padding-bottom: my-spacing(5)
            ),
            pb-4: (
                padding-bottom: my-spacing(4)
            ),
            pb-3: (
                padding-bottom: my-spacing(3)
            ),
            pb-2: (
                padding-bottom: my-spacing(2)
            ),
            pb-1: (
                padding-bottom: my-spacing(1)
            ),
            pb-0: (
                padding-bottom: 0
            ),
            pl-11: (
                padding-left: my-spacing(11)
            ),
            pl-10: (
                padding-left: my-spacing(10)
            ),
            pl-9: (
                padding-left: my-spacing(9)
            ),
            pl-8: (
                padding-left: my-spacing(8)
            ),
            pl-7: (
                padding-left: my-spacing(7)
            ),
            pl-6: (
                padding-left: my-spacing(6)
            ),
            pl-5: (
                padding-left: my-spacing(5)
            ),
            pl-4: (
                padding-left: my-spacing(4)
            ),
            pl-3: (
                padding-left: my-spacing(3)
            ),
            pl-2: (
                padding-left: my-spacing(2)
            ),
            pl-1: (
                padding-left: my-spacing(1)
            ),
            pl-0: (
                padding-left: 0
            ),
            m-11: (
                margin: my-spacing(11)
            ),
            m-10: (
                margin: my-spacing(10)
            ),
            m-9: (
                margin: my-spacing(9)
            ),
            m-8: (
                margin: my-spacing(8)
            ),
            m-7: (
                margin: my-spacing(7)
            ),
            m-6: (
                margin: my-spacing(6)
            ),
            m-5: (
                margin: my-spacing(5)
            ),
            m-4: (
                margin: my-spacing(4)
            ),
            m-3: (
                margin: my-spacing(3)
            ),
            m-2: (
                margin: my-spacing(2)
            ),
            m-1: (
                margin: my-spacing(1)
            ),
            m-0: (
                margin: 0
            ),
            mt-11: (
                margin-top: my-spacing(11)
            ),
            mt-10: (
                margin-top: my-spacing(10)
            ),
            mt-9: (
                margin-top: my-spacing(9)
            ),
            mt-8: (
                margin-top: my-spacing(8)
            ),
            mt-7: (
                margin-top: my-spacing(7)
            ),
            mt-6: (
                margin-top: my-spacing(6)
            ),
            mt-5: (
                margin-top: my-spacing(5)
            ),
            mt-4: (
                margin-top: my-spacing(4)
            ),
            mt-3: (
                margin-top: my-spacing(3)
            ),
            mt-2: (
                margin-top: my-spacing(2)
            ),
            mt-1: (
                margin-top: my-spacing(1)
            ),
            mt-0: (
                margin-top: 0
            ),
            mr-11: (
                margin-right: my-spacing(11)
            ),
            mr-10: (
                margin-right: my-spacing(10)
            ),
            mr-9: (
                margin-right: my-spacing(9)
            ),
            mr-8: (
                margin-right: my-spacing(8)
            ),
            mr-7: (
                margin-right: my-spacing(7)
            ),
            mr-6: (
                margin-right: my-spacing(6)
            ),
            mr-5: (
                margin-right: my-spacing(5)
            ),
            mr-4: (
                margin-right: my-spacing(4)
            ),
            mr-3: (
                margin-right: my-spacing(3)
            ),
            mr-2: (
                margin-right: my-spacing(2)
            ),
            mr-1: (
                margin-right: my-spacing(1)
            ),
            mr-0: (
                margin-right: 0
            ),
            mb-11: (
                margin-bottom: my-spacing(11)
            ),
            mb-10: (
                margin-bottom: my-spacing(10)
            ),
            mb-9: (
                margin-bottom: my-spacing(9)
            ),
            mb-8: (
                margin-bottom: my-spacing(8)
            ),
            mb-7: (
                margin-bottom: my-spacing(7)
            ),
            mb-6: (
                margin-bottom: my-spacing(6)
            ),
            mb-5: (
                margin-bottom: my-spacing(5)
            ),
            mb-4: (
                margin-bottom: my-spacing(4)
            ),
            mb-3: (
                margin-bottom: my-spacing(3)
            ),
            mb-2: (
                margin-bottom: my-spacing(2)
            ),
            mb-1: (
                margin-bottom: my-spacing(1)
            ),
            mb-0: (
                margin-bottom: 0
            ),
            ml-11: (
                margin-left: my-spacing(11)
            ),
            ml-10: (
                margin-left: my-spacing(10)
            ),
            ml-9: (
                margin-left: my-spacing(9)
            ),
            ml-8: (
                margin-left: my-spacing(8)
            ),
            ml-7: (
                margin-left: my-spacing(7)
            ),
            ml-6: (
                margin-left: my-spacing(6)
            ),
            ml-5: (
                margin-left: my-spacing(5)
            ),
            ml-4: (
                margin-left: my-spacing(4)
            ),
            ml-3: (
                margin-left: my-spacing(3)
            ),
            ml-2: (
                margin-left: my-spacing(2)
            ),
            ml-1: (
                margin-left: my-spacing(1)
            ),
            ml-0: (
                margin-left: 0
            ),
            // MAX-WIDTHS
            maxw-11:(
                max-width: 160px
            ),
            maxw-10:(
                max-width: 424px
            ),
            maxw-9:(
                max-width: 404px
            ),
            maxw-8: (
                max-width: 750px
            ),
            maxw-7:(
                max-width: 420px
            ),
            maxw-6:(
                max-width: 1440px
            ),
            maxw-5:(
                max-width: 890px
            ),
            maxw-4:(
                max-width: 520px
            ),
            maxw-3:(
                max-width: 1200px
            ),
            maxw-2:(
                max-width: 300px
            ),
            maxw-1:(
                max-width: 248px
            ),
            // MIN-HEIGHTS
            minh-1:(
                min-height: 640px
            ),
            minh-100vh: (
                min-height: 100vh
            ),
            // MIN-WIDTHS
            minw-6:(
                min-width: 350px
            ),
            minw-5:(
                min-width: 404px
            ),
            minw-4:(
                min-width: 420px
            ),
            minw-3:(
                min-width: 1200px
            ),
            minw-2:(
                min-width: 300px
            ),
            minw-1:(
                min-width: 248px
            ),
            // WIDTHS
            w-1:(
                width: 722px
            ),
            w-2: (
                width: 1072px
            ),
            w-3: (
                width: 24px
            ),
            w-4: (
                width: 264px
            ),
            w-5: (
                width: 202px
            ),
            w-6: (
                width: 66px
            ),
            w-7: (
                width: 315px
            ),
            // HEIGHTS
            h-1:(
                height: 386px
            ),
            h-2: (
                height: 447px
            ),
            h-3: (
                height: 472px
            ),
            h-4:(
                height: 707px
            ),
            h-5: (
                height: 830px
            ),
            h-6: (
                height: 430px 
            ),
            h-7: (
                height: 947px 
            ),
            h-8: (
                height: 24px
            ),
            h-9:(
                height: 20px
            ),
            h-10: (
                height: 213px
            ),
            h-11: (
                height:682px
            ),
            h-12: (
                height:170px
            ),
            // EXTENSION (workaround) 
            w-ext-1: (
                --ita-item-default-width: calc(500px + 100% * var(--ita-fraction) / var(--ita-total-amount-of-fractions))
            ),

            // c-dim m-checkbox-1
            checkbox-1: (
                pseudo-elements: (
                    before: (
                        selector: '+*',
                        content: '',
                        width: my-spacing(5),
                        height: my-spacing(5),
                        min-width: my-spacing(5),
                        min-height: my-spacing(5)
                    )
                )
            ),
            // c-dim m-radio-1
            radio-1: (
                pseudo-elements: (
                    before: (
                        selector: '+*',
                        content: '',
                        width: my-spacing(5),
                        height: my-spacing(5),
                        min-width: my-spacing(5),
                        min-height: my-spacing(5)
                    )
                )
            )
        )
    )
);